import {Event} from 'containers/Tracking/Tracking';
import React from 'react';
import {getOS} from 'utils/os';

import logoSrc from '../../../assets/images/business-card/strip-logo-black.png';
// import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

// const TextLogo = () => (
//   <svg
//     xmlns="http://www.w3.org/2000/svg"
//     xmlnsXlink="http://www.w3.org/1999/xlink"
//     width="134.378"
//     height="20.695"
//     viewBox="0 0 134.378 20.695"
//   >
//     <defs>
//       <linearGradient
//         id="linear-gradient"
//         x1="0.5"
//         x2="0.5"
//         y2="1"
//         gradientUnits="objectBoundingBox"
//       >
//         <stop offset="0" stopColor="#fd708b" />
//         <stop offset="1" stopColor="#ff6b70" />
//       </linearGradient>
//       <linearGradient
//         id="linear-gradient-3"
//         x1="0.5"
//         x2="0.5"
//         y2="1"
//         gradientUnits="objectBoundingBox"
//       >
//         <stop offset="0" stopColor="#009cfc" />
//         <stop offset="1" stopColor="#0176f1" />
//       </linearGradient>
//       <linearGradient
//         id="linear-gradient-4"
//         x1="0.5"
//         x2="0.5"
//         y2="1"
//         gradientUnits="objectBoundingBox"
//       >
//         <stop offset="0" stopColor="#19d7f2" />
//         <stop offset="1" stopColor="#01c6e2" />
//       </linearGradient>
//       <linearGradient
//         id="linear-gradient-5"
//         y1="1"
//         y2="0"
//         xlinkHref="#linear-gradient-3"
//       />
//     </defs>
//     <g
//       id="Group_2904"
//       data-name="Group 2904"
//       transform="translate(681 -3225.763)"
//     >
//       <g
//         id="Group_1636"
//         data-name="Group 1636"
//         transform="translate(-681 3225.763)"
//       >
//         <text
//           id="Calls"
//           transform="translate(0 13)"
//           fill="#515c6f"
//           fontSize="17"
//           fontFamily="MyriadHebrew-Regular, Myriad Hebrew"
//         >
//           <tspan x="0" y="0">
//             Calls
//           </tspan>
//         </text>
//       </g>
//       <g
//         id="Group_1745"
//         data-name="Group 1745"
//         transform="translate(-646.692 3227.986)"
//       >
//         <circle
//           id="Ellipse_261"
//           data-name="Ellipse 261"
//           cx="1.16"
//           cy="1.16"
//           r="1.16"
//           transform="translate(0 8.703)"
//           fill="url(#linear-gradient)"
//         />
//         <circle
//           id="Ellipse_262"
//           data-name="Ellipse 262"
//           cx="1.16"
//           cy="1.16"
//           r="1.16"
//           transform="translate(15.665 0)"
//           fill="url(#linear-gradient)"
//         />
//         <rect
//           id="Rectangle_1747"
//           data-name="Rectangle 1747"
//           width="2.321"
//           height="7.833"
//           rx="1.16"
//           transform="translate(15.665 2.901)"
//           fill="url(#linear-gradient-3)"
//         />
//         <rect
//           id="Rectangle_1748"
//           data-name="Rectangle 1748"
//           width="2.321"
//           height="11.314"
//           rx="1.16"
//           transform="translate(6.846 0.981) rotate(-25)"
//           fill="url(#linear-gradient-4)"
//         />
//         <rect
//           id="Rectangle_1749"
//           data-name="Rectangle 1749"
//           width="2.321"
//           height="11.314"
//           rx="1.16"
//           transform="translate(4.888 11.235) rotate(-155)"
//           fill="url(#linear-gradient-5)"
//         />
//       </g>
//     </g>
//   </svg>
// );

const PoweredBy = () => {
    // const { t } = useTranslation();
    const link = 'https://play.google.com/store/apps/details?id=ai.sync.call&hl=en&gl=US';
    const handleClickFooterPromotionBar = () => {
        Event('Leader - Business card', 'Click on footer promotion bar');
    };

    return (
        <a
            className={styles.container}
            // href="https://www.sync.ai/callsai"
            // href="https://play.google.com/store/apps/details?id=ai.sync.call"
            href={link}
            target='_blank'
            rel='noopener noreferrer'
            onClick={handleClickFooterPromotionBar}
        >
            <div className={styles.logo}>
                <img
                    className={styles.logo__image}
                    style={{width: 105, height: 105, right: 0, top: 0, position: 'fixed'}}
                    alt='Leader'
                    src={logoSrc}
                />
            </div>
            {/* <div className={styles.text}>
        <p className={styles.text__label}>{t('Powered by')}</p>
        <TextLogo />
      </div> */}
        </a>
    );
};

export default PoweredBy;
